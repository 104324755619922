




import Vue from 'vue';
import backend from '@/services/3xrCom';
import SpinnerButton from '@/components/buttons/SpinnerButton.vue';
import VueScrollTo from 'vue-scrollto';

interface Frame {
  src: string;
}
interface Tab {
  name: string;
  icon: string;
  link: string;
  iframes: Frame[];
}
interface IHomeData {
  dialog: boolean;
  errorMessage: string;
  input: {
    firstName: string;
    lastName: string;
    email: string;
    company: string;
    phone: string;
    message: string;
  };
  submitting: boolean;
  submitSuccess: boolean;
  tabs: Tab[];
  currentTab: number;
}
const initialTabs: Tab[] = [
  {
    name: 'Furniture',
    icon: 'mdi-table-furniture',
    link: 'furniture',
    iframes: [
      {
        src: 'https://www.3dmf.com/b/3xr/ahmn8g2qjbqg',
      },
      {
        src: 'https://www.3dmf.com/b/3xr/5wbnarebnf2u',
      },
      {
        src: 'https://www.3dmf.com/b/3xr/e4usttlaaqi8',
      },
    ],
  },
  {
    name: 'Equipment',
    icon: 'mdi-bike',
    link: 'equipment',
    iframes: [
      {
        src: 'https://www.3dmf.com/b/3xr/ypavz6c69dav',
      },
      {
        src: 'https://www.3dmf.com/b/3xr/ssv0569ri7az',
      },
      {
        src: 'https://www.3dmf.com/b/3xr/puc1cqctwpke',
      },
    ],
  },
  {
    name: 'Appliances',
    icon: 'mdi-coffee-maker',
    link: 'appliances',
    iframes: [
      {
        src: 'https://www.3dmf.com/b/3xr/sm94bkeiaxqi',
      },
      {
        src: 'https://www.3dmf.com/b/3xr/cag1b5p05c9s',
      },
      {
        src: 'https://www.3dmf.com/b/3xr/q6xtpm85xws0',
      },
    ],
  },
  {
    name: 'Style',
    icon: 'mdi-purse',
    link: 'style',
    iframes: [
      {
        src: 'https://www.3dmf.com/b/3xr/kigwfw1sphho',
      },
      {
        src: 'https://www.3dmf.com/b/3xr/8aely7noitqs',
      },
      {
        src: 'https://www.3dmf.com/b/3xr/fwuii3v1vpb9',
      },
    ],
  },
];

export default Vue.extend({
  name: 'home',
  metaInfo: {
    title: '3XR',
    meta: [
      {
        name: 'description',
        content: 'The 3XR platform provides endless opportunities to create amazing customer experiences.',
      },
      {
        name: 'keywords',
        content:
          '3XR, 360 spin products, AR platform, AR conversion, how to convert products to AR, convert images to AR, model conversion, products in AR, catalog in AR, make products 3D, easy AR, create augmented reality content, ar content, ar shopping, ar ecommerce, easy ar, 3d commerce, 3d modelling, affordable 3d models, view in room',
      },
      { property: 'og:image', content: 'https://cdn.3xr.com/images/fan-promo.jpg' },
    ],
  },
  components: {
    SpinnerButton,
  },
  data() {
    return {
      dialog: false,
      errorMessage: '',
      input: {
        firstName: '',
        lastName: '',
        email: '',
        company: '',
        phone: '',
        message: '',
      },
      submitting: false,
      submitSuccess: false,
      tabs: initialTabs,
      currentTab: 0,
    } as IHomeData;
  },
  computed: {
    shuffledTabs(): Tab[] {
      return this.shuffle(this.tabs);
    },
  },
  methods: {
    scrollTo: function (target: string) {
      var cancelScroll = VueScrollTo.scrollTo(target, 300, {
        container: 'body',
        easing: 'ease-in',
        offset: -60,
        force: true,
        cancelable: true,
        onStart: function (element) {
          // scrolling started
        },
        onDone: function (element) {
          // scrolling is done
        },
        onCancel: function () {
          // scrolling has been interrupted
        },
        x: false,
        y: true,
      });
    },
    submitInquireForm: function () {
      if (this.validateForm()) {
        this.submitting = true;
        backend
          .post('/contact_us', {
            firstName: this.input.firstName,
            lastName: this.input.lastName,
            email: this.input.email,
            phone: this.input.phone,
            company: this.input.company,
            message: this.input.message,
          })
          .then((result) => {
            this.submitting = false;
            if (result.data == 'success') {
              this.submitSuccess = true;
            } else {
              this.errorMessage = 'Sorry, there was an error. Please try again later.';
            }
          })
          .catch((err) => {
            this.errorMessage = err;
            this.submitting = false;
          });
      }
    },
    validateForm: function () {
      let firstName: HTMLInputElement = document.getElementById('firstName') as HTMLInputElement;
      if (!firstName.checkValidity()) {
        this.errorMessage = 'First Name is required';
        return false;
      }
      let lastName: HTMLInputElement = document.getElementById('lastName') as HTMLInputElement;
      if (!lastName.checkValidity()) {
        this.errorMessage = 'Last Name is required';
        return false;
      }
      let email: HTMLInputElement = document.getElementById('email') as HTMLInputElement;
      if (!email.checkValidity()) {
        this.errorMessage = 'Invalid Email';
        return false;
      }
      let message: HTMLInputElement = document.getElementById('message') as HTMLInputElement;
      if (!message.checkValidity()) {
        this.errorMessage = 'Please provide a message';
        return false;
      }
      return true;
    },
    shuffle(tabs: Tab[]) {
      return [...tabs].sort(() => Math.random() - 0.5);
    },
  },
  mounted() {
    const { tab } = this.$route.query;
    const selectedTabIdx = this.shuffledTabs.findIndex(({ link }) => link === tab);
    if (selectedTabIdx) {
      this.currentTab = selectedTabIdx;
    }
  },
});
